<template>
    <v-app id="menuLateral">
        <Menu :hide="!hideMenu" />

        <div :style="'position: fixed; width: 100%; height: 100%; background-color: #ddd; opacity: 0.6; z-index: 9999999;'
            + (loading ? '' : 'display: none;')">
            <v-img
                width="200"
                :src="require('@/assets/loading.gif')"
                style="top: 30%; margin: auto; z-index: 999999;"
            />
        </div>

        <slot name="principal" />

        <Confirm ref="confirm" />

        <v-snackbar
            v-model="snackbar.show"
            :timeout="2500"
            elevation="24"
            :color="snackbar.type === 'success' ? 'green accent-4' : 'error'"
        >
            <v-icon color="white">
                mdi-{{ snackbar.type === 'success' ? 'check-circle-outline' : 'close-circle-outline' }}
            </v-icon>
            {{ snackbar.text }}
        </v-snackbar>
    </v-app>
</template>

<script>
import Menu from '@/components/Menu';
import Confirm from '@/components/Confirm';
import { mapState, mapMutations, mapActions } from 'vuex';

export default {
    name: 'BaseContainer',

    components: {
        Menu,
        Confirm,
    },

    props: ['hideMenu'],

    data: () => ({
        loading: false,
        snackbar: { show: false, text: '', type: '' },
    }),

    async mounted() {
        this.$root.$on('loading', load => (this.setLoading(load)));
        this.$root.$confirm = this.$refs.confirm.open;

        this.$vuetify.theme.dark = true;

        await this.initialize();
        this.setUpWs();
        this.setListeners();
    },

    beforeDestroy() {
        this.removeListeners();
    },

    computed: {
        ...mapState([
            'usuario',
        ]),
    },

    methods: {
        ...mapActions([
            'setUpWs',
        ]),

        ...mapMutations({
            setUsuario: 'setUsuario',
        }),

        setLoading(loading) {
            this.loading = loading;
        },

        setSnackbar(data) {
            data.show = true;
            this.snackbar = data;
        },

        setListeners() {
            this.$root.$on('notify', data => (this.setSnackbar(data)));
        },

        removeListeners() {
            this.$root.$off('notify');
        },

        async initialize() {
            if (this.$route.path === '/login' || this.usuario.id) {
                return;
            }

            await this.checkUser();
        },

        async checkUser() {
            try {
                const { data } = await this.$http.post('usuarios/get-auth');
                this.setUsuario(data.data);
            } catch (e) {
                console.log(e);
            }
        },
    },
};
</script>
